import React from 'react';
import './css/landing.css'

export default class SandiTemplate extends React.Component {
    render() {
        return (
            <div className="container landing">
                {
                    this.props.children
                }
                <footer><div className="logo"></div></footer>
            </div>
        )
    }
}