import React from 'react';
import CryptoJS from 'crypto-js';
import {v4 as uuidv4} from 'uuid';

export default class PFCookiePage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            cookie: "",
            codeVerifier: ""
        }
    }
    componentDidMount() {
        //Because we don't know how to undo all the things that liveperson create in the window context/html, we refresh the whole page when back is pressed in the browser
        window.onpopstate = () => {
            window.location.href = '/index.html';
        }
    }

    render() {
        return <div className="mb-4">
        <button className="btn btn-success" onClick={ () => this._getCookieFromBackend(this.props.isRetail) }>Get Cookie</button>
        <label className="form-label">or Insert your cookie here
                                <input className="form-control" value={this.cookie}
                                    onChange={(e) => this.setState({ cookie: e.target.value })}
                                    autoComplete="on" />
                            </label>
        <button className="btn btn-success" onClick={ () => this._saveCookie() }>Save Cookie</button>
    </div>
    }

    _saveCookie = async () => {
        document.cookie = `PF=${this.state.cookie}`
    }
    //1. Authorize
    _getCookieFromBackend = async () => {

        const configurl = 'https://auth.pre.identity.gamma.tlzproject.com/.well-known/openid-configuration'
        await fetch(configurl, { method: 'GET', headers: { 
            'Content-Type': 'application/json'
        }});
        
        var state = CryptoJS.SHA256(uuidv4());
        var nonce = CryptoJS.SHA256(uuidv4());
        this.codeVerifier = this.generateCodeVerifier();
        var codeChallenge = this.generateCodeChallenge(this.codeVerifier);
        const url = `https://auth.pre.identity.gamma.tlzproject.com/as/authorization.oauth2?response_type=code%20id_token&client_id=3fr4tbk3afpj4llzgzr1kbmfn&redirect_uri=https://globalposition&scope=openid%20auth-server.logon-session.create&state=${state}&nonce=${nonce}&code_challenge=${codeChallenge}&code_challenge_method=S256&appVersion=0.0.3`;

        try {
            const response = await fetch(url, { method: 'GET', headers: { 
                'Content-Type': 'application/json',
                'redirect': 'manual'

            }});
            const responseBody = await response.json();
            if(response.redirected){
                console.log("Cookie saved, flow created")
                console.log(response)
                var flow = response.url.substring(response.url.length - 10)
                console.log(flow)
                this._identifyMobileApplication(flow)
            }else{
                alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
            }
        } catch (error) {
            alert(`error getting cookies: ${error}`);
        }
    }

    //3b. Action identifyMobileApplication
    _identifyMobileApplication = async (flow) => {
        const url = `https://auth.pre.identity.gamma.tlzproject.com/pf-ws/authn/flows/${flow}?action=identifyMobileApplication`;
            var myHeaders = new Headers();
            myHeaders.append("X-XSRF-Header", "PingFederate");
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "appType": "ios",
            "appVersion": "0.0.2",
            "deviceModel": "iPhone8-1",
            "deviceOS": "12.2",
            "deviceFootprint": "f3ikmAV26ops7lTedgjkY03nOcBEZ0r0aHyZtPKRRPg=",
            "deviceRegKeyFlag": false,
            "biometricChangeFlag": false,
            "biometricLogonPrefFlag": false,
            "biometricAuthPrefFlag": false,
            "deviceAuthenticator": "iOS9 SE EllipticCurve",
            "biometricAuthenticator": "iOS9 TouchID SE EllipticCurve",
            "trusteerSessionId": "0f1b2259-458f-4865-9307-13d4829d58a2"
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
            try {
                const response = await fetch(url, requestOptions);
                const responseBody = await response.json();
                if(response.ok){
                    console.log("infentifyMobileApp done")
                    this._evaluatePID(flow)
                }else{
                    alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
                }
        } catch (error) {
            alert(`error step 2: ${error}`);
        }
    }

    // 4. Evaluate PID
    _evaluatePID= async (flow) => {
        const url = `https://auth.pre.identity.gamma.tlzproject.com/pf-ws/authn/flows/${flow}?action=evaluatePersonalId`;

        var myHeaders = new Headers();
        myHeaders.append("X-XSRF-Header", "PingFederate");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "personalId": "17224td11"
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        try {
            const response = await fetch(url, requestOptions);
            const responseBody = await response.json();
            if(response.ok){
                console.log("evaluatePID done, uid " + responseBody.uid)
                console.log(responseBody)
                this._evaluateSecurityNumber(flow, responseBody.uid)

            }else{
                alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
            }
    } catch (error) {
        alert(`error getting cookies: ${error}`);
    }
    }
    // 5.evaluateSecurityNumber
    _evaluateSecurityNumber= async (flow, uid) => {
        const url = `https://auth.pre.identity.gamma.tlzproject.com/pf-ws/authn/flows/${flow}?action=evaluateSecurityNumber`
        var myHeaders = new Headers();
        myHeaders.append("X-XSRF-Header", "PingFederate");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "securityNumber": "13579"
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        try {
            const response = await fetch(url, requestOptions);
            const responseBody = await response.json();
            if(response.ok){
                console.log("evaluateSecurityNumber done")
                this._requestSMSOTP(flow, uid)
            }else{
                alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
            }
        } catch (error) {
            alert(`error getting cookies: ${error}`);
        }
    }

    // 6a. requestSMSOTP
    _requestSMSOTP= async (flow, uid) => {
        const url = `https://auth.pre.identity.gamma.tlzproject.com/pf-ws/authn/flows/${flow}?action=requestSMSOTP`
        var myHeaders = new Headers();
        myHeaders.append("X-XSRF-Header", "PingFederate");
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
        };

        try {
            const response = await fetch(url, requestOptions);
            const responseBody = await response.json();
            if(response.ok){
                console.log("request SMOTP done")
                this._evaluateSMSOTP(flow, uid)
            }else{
                alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
            }
        } catch (error) {
            alert(`error getting cookies: ${error}`);
        }
    }

    // 6b. evaluateSMSOTP
    _evaluateSMSOTP= async (flow, uid) => {
        var otp
        var apiHeaders = new Headers();
        apiHeaders.append("x-ibm-client-id", "7cbf2d68-ac95-4755-81e6-01cdbd19b1ac")
        var apiRequestOptions = {
            method: 'GET',
            headers: apiHeaders,
            redirect: 'follow'
          };
        console.log(`Retrieve otp from api ${uid}`)
        const apiResponse = await fetch(
            `https://intra-core-api-dev.santanderuk.pre.corp/sanuk/internal/fraud-prevention-utilities/sms-otp-retrieve/${uid}`,
            apiRequestOptions);
        const apiResponseBody = await apiResponse.json();
        if(apiResponse.ok){
                otp = apiResponseBody.otpCode
                console.log("otp code " + otp)
        }else{
                alert(`error getting otp: ${JSON.stringify(apiResponseBody)}`)
        }

        const url = `https://auth.pre.identity.gamma.tlzproject.com/pf-ws/authn/flows/${flow}?action=evaluateSMSOTP`
        var myHeaders = new Headers();
        myHeaders.append("X-XSRF-Header", "PingFederate");
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "oneTimePasscode": otp
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        try {
            const response = await fetch(url, requestOptions);
            const responseBody = await response.json();
            if(response.ok){
                console.log("evaluate SMOTP done")
                this._registerDevice(flow)
            }else{
                alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
            }
        } catch (error) {
            alert(`error getting cookies: ${error}`);
        }
    
    }
    // 8. registerDevice
    _registerDevice= async (flow, uid) => {
        var customerId 
        var customerUUID 
        var apiHeaders = new Headers();
        apiHeaders.append("X-XSRF-Header", "PingFederate")
        var apiRequestOptions = {
            method: 'GET',
            headers: apiHeaders
            };
        console.log(`Progress flow to ping federate`)
        const apiResponse = await fetch(
            `https://auth.pre.identity.gamma.tlzproject.com/pf-ws/authn/flows/${flow}`,
            apiRequestOptions);
        const apiResponseBody = await apiResponse.json();
        if(apiResponse.ok){
                customerId = apiResponseBody.customerId
                customerUUID = apiResponseBody.customerUUID
                console.log("customer details " + customerId + customerUUID)

        }else{
                alert(`error getting otp: ${JSON.stringify(apiResponseBody)}`)
        }

        const url = `https://auth.pre.identity.gamma.tlzproject.com/pf-ws/authn/flows/${flow}?action=registerDevice`
        var myHeaders = new Headers();
        myHeaders.append("X-XSRF-Header", "PingFederate");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "deviceAuthenticator": "iOS9 SE EllipticCurve",
            "deviceHash": "SHA256withRSA",
            "algorithm": "RSA",
            "devicePublicKey": "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAp2VEkhy7qx2x3IicBObWsliSsuFOykr3V8XHqFO/3f44+US6onO5EqZHA3o2Dffanz5M7cI/NESYdvSPcopMPTAYBirTiT9DH5qcZQ/5ot3mqPZOEt1vzhTuZwFOrfxXmwbI2RT1/rS7g6LL/JA7vm9HovTLTjIHWcs1jUBNtnMsJuMFjuP+Crx9kX3HVxZvit4SLmrTn1IFku/wqn2IML9BZH1IH6+DhHGIO64ieWc6QcQsWykgNT25VBPhoRdmTbVo4CFTagslIqOx4RJPLUqxRH0fS5av2XWv/mWgRhWYqa5DCT5tZiJapjfouRVwtStNopQHo/bon0LDLqD57QIDAQAB"
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        try {
            const response = await fetch(url, requestOptions);
            const responseBody = await response.json();
            if(response.ok){
                console.log("register device done")
                this._confirm(flow)
            }else{
                alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
            }
        } catch (error) {
            alert(`error getting cookies: ${error}`);
        }
    }

        // 9 Confirm all authorization steps
        _confirm= async (flow) => {
            console.log("confirming flow " + flow)
            const url = `https://auth.pre.identity.gamma.tlzproject.com/as/${flow}/resume/as/authorization.ping`
            var myHeaders = new Headers();
            myHeaders.append("redirect", "manual");
            var requestOptions = {
                method: 'GET',
                redirect: 'manual',
                headers: myHeaders
            };

            try {
                const response = await fetch(url, requestOptions);
                const responseBody = await response.json();
                console.log(response)
                if(response.redirected){
                    console.log("flow completed")
                    this._accessToken(response.headers.split("#code=")[1].split("&")[0])
                }else{
                    alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
                }
            } catch (error) {
                alert(`error getting cookies: ${error}`);
            }
        }

        // Generate access token
        _accessToken= async (code) => {
            const url = `https://auth.pre.identity.gamma.tlzproject.com/as/token.oauth2`
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("grant_type", "authorization_code");
            urlencoded.append("code", code);
            urlencoded.append("redirect_uri", "https://globalposition");
            urlencoded.append("client_id", "3fr4tbk3afpj4llzgzr1kbmfn");
            urlencoded.append("code_verifier", this.codeVerifier);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'manual'
            };

            try {
                const response = await fetch(url, requestOptions);
                const responseBody = await response.json();
                if(response.ok){
                    console.log("flow completed")
                }else{
                    alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
                }
            } catch (error) {
                alert(`error getting cookies: ${error}`);
            }
        }

    generateCodeVerifier() {
        var code_verifier = this.generateRandomString(128)
        return code_verifier
    }
    generateRandomString(length) {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~";
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    generateCodeChallenge(code_verifier) {
        return this.base64URL(CryptoJS.SHA256(code_verifier))
    }
    base64URL(string) {
        return string.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
    }

}
