import React from 'react';

export default class GlobalChatbotPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            backgroundHtml: '',
        }
    }
    componentDidMount() {
        //Because we don't know how to undo all the things that liveperson create in the window context/html, we refresh the whole page when back is pressed in the browser
        window.onpopstate = () => {
            window.location.href = '/index.html';
        }

        var script = document.createElement("script");  // create a script DOM node
        script.src = `${this.props.base}/js/virtual-assistant-chatbot.js`;

        document.head.appendChild(script);

    }

    render() {
        return <div>
            <va-chatbot context='{"referrerId": "secureRetailOneApp"}' autoconfig={`${this.props.base}/config/va-front.json`}></va-chatbot></div>
    }
}