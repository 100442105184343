export default class CookieUtils {
    static getNewUniversalCookie(){
        const cookies = document.cookie.split(";")
        const cookieFiltered = cookies.filter(it => it.trim().startsWith("NewUniversalCookie"))
        if (cookieFiltered.length === 1) {
            return cookieFiltered[0];
        } else {
            throw new Error("Error, new universal cookie not detected")
        }
    }
    static existNewUniversalCookie(){
        try{
            CookieUtils.getNewUniversalCookie();
            return true;
        }catch(e){
            return false;
        }  
    }
}