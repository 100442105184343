import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.scss';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import LandingPage from './pages/LandingPage.js';
import AuthProvider from './components/AuthProvider.js'
import ChatbotPage from './components/ChatbotPage';
import SalesforcePoc from './pages/salesforce/SalesforcePoc'
import GlobalChatbotPage from './pages/globalChatbotPage/GlobalChabotPage';
import GlobalChatbotWebview from './pages/globalChatbotWebview/GlobalChabotWebview';
import PFCookiePage from './pages/pfCookiePage/PFCookiePage';

const root = ReactDOM.createRoot(document.getElementById('root'));
document.cookie = "NewUniversalCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; //delete previous cookie
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="index.html" element={<LandingPage />} />
      <Route path="public-retail" element={
        <ChatbotPage backgroundPath="/backgrounds/public-retail.html" />
      } />
       <Route path="public-retail-shadow" element={
          <ChatbotPage backgroundPath="/backgrounds/public-retail.html" environment="shadow" />
        }/>
      <Route path="mortgages" element={
        <AuthProvider title="Secure Retail (mortgages) login">
          <ChatbotPage backgroundPath="/backgrounds/mortgages.html" />
        </AuthProvider>
      } />
      <Route path="secure-retail" element={
        <AuthProvider title="Secure Retail login">
          <ChatbotPage sectionName="auth-demo-section" backgroundPath="/backgrounds/secure-retail.html" />
        </AuthProvider>
      } />
      <Route path="sandi-secure-retail-dev" element={
        <AuthProvider title="Secure Retail login">
          <GlobalChatbotPage backgroundPath="/backgrounds/secure-retail.html" referrerId="secureRetailAws" base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/dev" />
        </AuthProvider>
      } />
      <Route path="sandi-secure-retail-pre" element={
        <AuthProvider title="Secure Retail login">
          <GlobalChatbotPage backgroundPath="/backgrounds/secure-retail.html" referrerId="secureRetailAws" base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/pre" />
        </AuthProvider>
      } />
      <Route path="secure-business" element={
        <AuthProvider title="Secure Business login" isRetail={false}>
          <ChatbotPage sectionName="auth-sbbi-section" backgroundPath="/backgrounds/secure-business.html" />
        </AuthProvider>
      } />
      <Route path="sandi-secure-business-dev" element={
        <AuthProvider title="Secure Business login" isRetail={false}>
          <GlobalChatbotPage backgroundPath="/backgrounds/secure-business.html" referrerId="secureBusinessAws" base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/dev" />
        </AuthProvider>
      } />
      <Route path="sandi-secure-business-pre" element={
        <AuthProvider title="Secure Business login" isRetail={false}>
          <GlobalChatbotPage backgroundPath="/backgrounds/secure-business.html" referrerId="secureBusinessAws" base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/pre" />
        </AuthProvider>
      } />
      <Route path="local" element={
        <AuthProvider title="Secure Retail Local login">
          <ChatbotPage backgroundPath="/backgrounds/secure-retail.html" />
        </AuthProvider>
      } />
      <Route path="salesforce-poc" element={<SalesforcePoc />} />
      <Route path="global-dev" element={<GlobalChatbotPage backgroundPath="/backgrounds/public-retail.html" referrerId="publicMain" base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/dev"/>} />
      <Route path="global-pre" element={<GlobalChatbotPage backgroundPath="/backgrounds/public-retail.html" referrerId="publicMain"  base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/pre"/>} />
      <Route path="global-shadow" element={<GlobalChatbotPage referrerId="publicShadow" base="https://dam.santander.co.uk/content/dam/publish/virtualassistant/shadow"/>} />
      <Route path="secure-retail-webview-dev" element={<GlobalChatbotWebview backgroundPath="/backgrounds/secure-retail.html" base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/dev"/>} />
      <Route path="secure-retail-webview-pre" element={<GlobalChatbotWebview backgroundPath="/backgrounds/secure-retail.html" base="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/pre"/>} />
      <Route path="pf-cookie" element={<PFCookiePage/>} />

      <Route path="*" element={<LandingPage />} />
    </Routes>
  </BrowserRouter>
);


