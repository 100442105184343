import React from 'react';
import LivePerson from '../liveperson/LivePerson.js';
import addChatbotBehavior from '../SandiGlobalBubble/SandiGlobalBubble.js';
export default class ChatbotPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            backgroundHtml: '',
        }
    }

    componentDidMount() {
        LivePerson.init(this.props.environment || "pre");

        LivePerson.loadBubbles(this.props.sectionName)

        //Because we don't know how to undo all the things that liveperson create in the window context/html, we refresh the whole page when back is pressed in the browser
        window.onpopstate = () => {
            window.location.href = '/index.html';
        }

        fetch(this.props.backgroundPath)
            .then(background => background.text())
            .then(html => {
                this.setState({
                    backgroundHtml: html,
                })
            })
    }

    // This has been added to test Global chat invite, because it needs to detect when LP adds new HTML
    componentDidUpdate() {
        addChatbotBehavior();
    }

    render() {
            return <div dangerouslySetInnerHTML={{ __html: this.state.backgroundHtml }} />
    }
}