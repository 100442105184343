import CookieUtils from "../utils/CookieUtils";
import config from "../config/config";
export default class LivePerson {
    static init(environment) {
        window.lpTag = window.lpTag || {}

        if ('undefined' == typeof window.lpTag._tagCount) {
            window.lpTag = {
                wl: window.lpTag.wl || null,
                scp: window.lpTag.scp || null,
                site: config.livePerson.account[environment] || "",
                section: window.lpTag.section || '',
                tagletSection: window.lpTag.tagletSection || null,
                autoStart: window.lpTag.autoStart !== !1,
                ovr: window.lpTag.ovr || {},
                _v: '1.10.0',
                _tagCount: 1,
                protocol: 'https:',
                events: {
                    bind: function (t, e, i) {
                        window.lpTag.defer(function () {
                            window.lpTag.events.bind(t, e, i);
                        }, 0);
                    },
                    trigger: function (t, e, i) {
                        window.lpTag.defer(function () {
                            window.lpTag.events.trigger(t, e, i);
                        }, 1);
                    },
                },
                defer: function (t, e) {
                    if (0 === e) {
                        this._defB = this._defB || []
                        this._defB.push(t)
                    } else if (1 === e) {
                        this._defT = this._defT || []
                        this._defT.push(t)
                    } else {
                        this._defL = this._defL || []
                        this._defL.push(t)
                    }
                },
                load: function (t, e, i) {
                    var n = this;
                    setTimeout(function () {
                        n._load(t, e, i);
                    }, 0);
                },
                _load: function (t, e, i) {
                    var n = t;
                    t ||
                        (n =
                            this.protocol +
                            '//' +
                            (this.ovr && this.ovr.domain ? this.ovr.domain : 'lptag.liveperson.net') +
                            '/tag/tag.js?site=' +
                            this.site);
                    var o = document.createElement('script');
                    o.setAttribute('charset', e ? e : 'UTF-8')
                    i && o.setAttribute('id', i)
                    o.setAttribute('src', n)
                    document.getElementsByTagName('head')
                        .item(0)
                        .appendChild(o);
                },
                init: function () {
                    this._timing = this._timing || {}
                    this._timing.start = new Date().getTime();
                    var t = this;
                    if (window.attachEvent) {
                        window.attachEvent('onload', function () {
                            t._domReady('domReady');
                        })
                    } else {
                        window.addEventListener('DOMContentLoaded',function () {
                                t._domReady('contReady');
                        },!1)
                        window.addEventListener('load',function () {
                                t._domReady('domReady');
                        },!1)
                    }
                    'undefined' === typeof window._lptStop && this.load();
                },
                start: function () {
                    this.autoStart = !0;
                },
                _domReady: function (t) {
                    if(!this.isDom){
                        this.isDom = true
                        this.events.trigger('LPT', 'DOM_READY', { t: t })
                    }
                    this._timing[t] = new Date().getTime();
                },
                vars: window.lpTag.vars || [],
                dbs: window.lpTag.dbs || [],
                ctn: window.lpTag.ctn || [],
                sdes: window.lpTag.sdes || [],
                hooks: window.lpTag.hooks || [],
                identities: window.lpTag.identities || [],
                ev: window.lpTag.ev || [],
            }
            //window.lpTag.init()
        } else {
            window.lpTag._tagCount += 1
        } 
    }
    static loadBubbles(section) {
        // Execution order of this code and LP Monitor code below is a little flexible, but need to make the definitions
        // before LP tag detects the page is 'loaded' and ready to go:
        if (!window.lpTag) window.lpTag = {};
        if (!window.lpTag.identities) window.lpTag.identities = [];

        // Appropriate tag attributes required to trigger the engagement (depends on LP config):
        if(section){
            window.lpTag.section = [section];
        }else{
            window.lpTag.section = [];
        }
        

        // Identity function - always called, enables LP to track customer's journey through the site:
        function identityFn(callback) {
            callback({
                iss: 'auth-demo-page',
                acr: 'loa1', // This is always 'loa1' - included for sake of standards compliance
                sub: 'a-unique-non-guessable-customer-id', // An ID we're happy to share with customer device
            });
        }

        window.lpTag.identities.push(identityFn);

        window.lpMethods = {
            lpGetAuthenticationToken: function (callback) {
                var xhr = new XMLHttpRequest();
                xhr.withCredentials = false;

                xhr.addEventListener('load', function () {
                    var jsonResponse = JSON.parse(xhr.response);
                    const location = jsonResponse.location;
                    console.log("location: " + location)
                    const codeParam = 'code=';
                    const locationIndex = location.indexOf(codeParam);
                    console.log("locationIndex: " + locationIndex)
                    if (location !== undefined && locationIndex !== -1) {
                        const authCode = location.substring(locationIndex + codeParam.length);
                        console.log("authCode: " + authCode)
                        callback(authCode);
                    }
                    else {
                        console.log('Authcode request failed, location missing')
                        callback('', 'Authcode request failed, location missing');
                    }
                });

                xhr.addEventListener('error', function (evt) {
                    console.error('Authcode request failed', evt);
                    callback('', 'Authcode request failed');
                });
                let nuc = CookieUtils.getNewUniversalCookie();
                xhr.open('GET', `${config.backend_url}/authorize`);
                xhr.setRequestHeader('Accept', 'application/json');
                xhr.setRequestHeader('cache-control', 'no-cache');
                xhr.setRequestHeader('x-nuc', nuc);
                xhr.send();
            },
        };
        //show bubbles
        window.lpTag.init()
    }
}
