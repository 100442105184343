import React from 'react';
import { Link } from "react-router-dom";
import SandiTemplate from '../components/sandiTemplate/SandiTemplate.js';

export default class LandingPage extends React.Component {
    render() {
        return (
            <SandiTemplate>
                <section>
                    <h1>Sandi is here to help you!</h1>
                    <p>Sandi is your automated personal assistant for work related queries! You can use Sandi in different flavours:</p>
                    <ul>
                        <h3>Liveperson Secure</h3>
                        <li><Link to="/secure-retail">Secure Retail</Link></li>
                        <li><Link to="/secure-business">Secure Business</Link></li>
                        <li><Link to="/mortgages">Mortgages</Link></li>

                        <h3>Sandi Frontend Public</h3>
                        <li><Link to="/global-dev">DEV</Link></li>
                        <li><Link to="/global-pre">PRE</Link></li>
                        <li><Link to="/global-shadow">SHADOW</Link></li>
                        <li><Link to="https://integrations-chatbot-pre.santanderuk.pre.corp/">Shadow Sandi Look&Feel/CSS tests (Reverse Proxy)</Link></li>

                        <h3>Sandi Frontend OneApp Webview </h3>
                        <li><Link to="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/dev/webview/index.html">DEV</Link></li>
                        <li><Link to="https://santander-uk-assets-stage-2.adobecqms.net/content/dam/publish/virtualassistant/pre/webview/index.html">PRE</Link></li>
                        <li><Link to="https://dam.santander.co.uk/content/dam/publish/virtualassistant/live/webview/index.html">Live</Link></li>
                        
                        <h3>Sandi Frontend OLB</h3>

                        <h4>Secure Retail</h4>
                        {/* <li><Link to="/sandi-secure-retail-dev">DEV</Link></li> */}
                        <li><Link to="/sandi-secure-retail-pre">PRE</Link></li>

                        <h4>Secure Business</h4>
                        {/* <li><Link to="/sandi-secure-business-dev">DEV</Link></li> */}
                        <li><Link to="/sandi-secure-business-pre">PRE</Link></li>

                        {/* <h3>Amazon connect</h3>
                        <li><Link to="/agentPortal/agentPortal.html">Agent Portal</Link></li> */}
                        
                        <h3>Developers</h3>
                        <li><Link to="/local">LP Local Testing (only used by developers)</Link></li>
                    </ul>
                </section>
            </SandiTemplate>
        )
    }
}
