export default function addChatbotBehavior() {
    const livepersonChatbotAnchor = document.querySelector('#LP_DIV_1672746642946');
    if (livepersonChatbotAnchor) {
        livepersonChatbotAnchor.addEventListener('DOMNodeInserted', function(event) {
            if (event.target.id === 'chatbotEntryPoint') {
                const chatbotEntryPoint = event.target;
                const chatbotImage = chatbotEntryPoint.querySelector('#chatbotImage');
                chatbotImage.addEventListener('mouseenter', ()=>chatbotEntryPoint.classList='animation_expand');
                chatbotImage.addEventListener('mouseleave', ()=>chatbotEntryPoint.classList='animation_collapse');
            }
        });
    }
}