import React from 'react';
import CookieUtils from "../utils/CookieUtils";
import LoginPage from '../pages/LoginPage';

export default class AuthProvider extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            isAuthenticated: CookieUtils.existNewUniversalCookie()
        }
    }
    render() {
        if (this.state.isAuthenticated) {
            return this.props.children
        } else {
            return <LoginPage onLoggedIn={()=>this.setState({isAuthenticated:true})} title={ this.props.title } isRetail={ this.isRetail() } />
        }
    }
    isRetail = () => {
        if (this.props.isRetail === undefined) {
            return true // Default value
        }
        else {
            return this.props.isRetail
        }
    }
}