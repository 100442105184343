const config = {
    backend_url: "https://backend.dev-chatbot.private.gamma.tlzproject.com",
    livePerson: {
        account: {
            pre: "15025973",
            shadow: "65561538"
        }
    }
}

export default config