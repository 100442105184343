import React from 'react';
import './resources/style.css'
export default class LandingPage extends React.Component {
    render() {
        return (
            <div></div>
        )
    }
    componentDidMount(){
		var s = document.createElement('script');
		s.setAttribute('src', 'https://chatpoc-chatpoc.cs110.force.com/ESWSanDevInAppWeb1668179606552/assets/js/bootstrap.min.js');
		s.onload = ()=> {
			this.initEmbeddedMessaging();
		};
		document.body.appendChild(s);
    }

	initEmbeddedMessaging() {
		try {
			console.log("initEmbeddedMessaging called")
			window.embeddedservice_bootstrap.settings.language = 'en'; // For example, enter 'en' or 'en-US'

			window.embeddedservice_bootstrap.init(
				'00D0C0000000kPp',
				'SanDevInAppWeb',
				'https://chatpoc-chatpoc.cs110.force.com/ESWSanDevInAppWeb1668179606552',
				{
					scrt2URL: 'https://sanukretail--chatpoc.my.salesforce-scrt.com'
				}
			);
			console.log("window.embeddedservice_bootstrap.init called")
		} catch (err) {
			console.error('Error loading Embedded Messaging: ', err);
		}
	};
}