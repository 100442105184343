import React from 'react';
import SandiTemplate from '../components/sandiTemplate/SandiTemplate.js';
import config from "../config/config"

export default class LoginPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            UID: "",
            customerPassword: ""
        }
    }
    render() {
        return (
            <SandiTemplate>
                <h1>{ this.props.title }</h1>
                <div className="row">
                    <div className="col-2">
                        <div className="mb-4">
                            <label className="form-label">User Id
                                <input className="form-control" value={this.state.UID}
                                    onChange={(e) => this.setState({ UID: e.target.value })}
                                    autoComplete="on" />
                            </label>
                        </div>
                        <div className="mb-4">
                            <label className="form-label">Customer Password
                                <input type="password" className="form-control" value={this.state.customerPassword}
                                    onChange={(e) => this.setState({ customerPassword: e.target.value })}
                                    autoComplete="on" />
                            </label>
                        </div>
                        <div className="mb-4">
                            <button className="btn btn-success" onClick={ () => this._getCookieFromBackend(this.props.isRetail) }>Get Cookie</button>
                        </div>
                    </div>
                </div>
            </SandiTemplate>
        )
    }

    _getCookieFromBackend = async (isRetail) => {
        const url = `${config.backend_url}/get-cookie`;
        const body = {
            UID: this.state.UID,
            customerPassword: this.state.customerPassword,
            isRetail
        }

        try {
            const response = await fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) });
            const responseBody = await response.json();
            if(response.ok){
                document.cookie = `NewUniversalCookie=${responseBody.nuc}`
                this.props.onLoggedIn()
            }else{
                alert(`error getting cookies: ${JSON.stringify(responseBody)}`)
            }
        } catch (error) {
            alert(`error getting cookies: ${error}`);
        }
    }
}